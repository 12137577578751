import React, { useState } from 'react';
import i18next from 'i18next';
import Modal from 'react-awesome-modal';
import './modales.css';
import close from '../../../assets/logo/close.svg';
import arrow from '../../../assets/images/arrow-blue.svg';
import { ReactComponent as Warning } from '../../../assets/travel/important.svg';

const noScroll = require('no-scroll');

const AncillarBtn = ({ image, title, description, data, icon }) => {
	const [visible, setVisible] = useState(false);

	const closeModal = () => {
		setVisible(false);
		noScroll.off();
	};

	const openModal = () => {
		setVisible(true);
		noScroll.on();
	};

	return (
		<div>
			{!!data ? (
				<ModalImplement
					data={data}
					visible={visible}
					closeModal={closeModal}
					icon={icon}
				/>
			) : null}
			<button
				type="button"
				onClick={openModal}
				className="ancillar-card relative z-10 my-[10px] flex h-full w-full flex-col items-center justify-start rounded-lg border-none outline-none hover:z-20 focus:outline-none"
			>
				<img
					src={image}
					alt="Premiere Upgrade"
					className="card-image-holder w-full rounded-lg object-cover shadow-2xl group-hover:shadow-2xl"
					loading="lazy"
				/>
				<h5 className="totalBenefitsTravel__card__title mt-4 w-full px-1 text-left">
					{title}
				</h5>
				<p className="totalBenefitsTravel__card__text mt-1 mb-3 px-1 text-left">
					{description}
				</p>
				<span className="travel-link absolute left-0 bottom-0 mt-3 flex w-full items-center justify-start px-2">
					<p className="totalBenefitsTravel__card__more">
						{i18next.t('MiExperAM.travel.ancillares.conoce')}
					</p>
					<img
						className="blue-arrow ml-2"
						src={arrow}
						alt="blue-arrow"
					/>
				</span>
			</button>
		</div>
	);
};

export default AncillarBtn;

const ModalImplement = ({ visible, closeModal, data, icon }) => {
	const theTable = !!data.table ? JSON.parse(data.table) : null;

	return (
		<Modal
			visible={visible}
			width="auto"
			height="auto"
			effect="fadeInUp"
			onClickAway={closeModal}
		>
			<div className="modalWindowAncillaries">
				<div className="closeModal" onClick={closeModal}>
					<img src={close} alt="close modal" />
				</div>

				<div className="topBox">
					<div className="header flex items-center justify-start">
						{!!icon ? (
							<img
								className="imageCircle"
								src={icon}
								alt={data.title}
							/>
						) : null}
						<div className="textLeftCircle m-0">
							<p className="m-0 text-2xl font-medium text-amBlack">
								{data.title}
							</p>
							{!!data.subtitle ? (
								<p className="subtitle">{data.subtitle}</p>
							) : null}
						</div>
					</div>

					<div className="contentType upgrade">
						{!!data.firstAlert ? (
							<div className="flex flex-col items-center justify-center">
								<div className="alert mb-3 flex items-center justify-start rounded-lg bg-amRegularBlue p-3">
									<p
										dangerouslySetInnerHTML={{
											__html: i18next.t(
												'MiExperAM.travel.ancillares.upgrade.small_preview'
											)
										}}
										className="small-text-alert-modal small-text-alert-modal m-0 ml-2 leading-normal"
									/>
								</div>
								<Warning className="mb-4 h-8 w-8 fill-current text-amRegularBlue" />
							</div>
						) : null}
						<div
							className="regular-text-content prose pt-4 font-normal text-amBlack"
							style={{ marginBottom: '20px' }}
							dangerouslySetInnerHTML={{ __html: data.content }}
						/>

						{!!data.endAlert ? (
							<div className="flex flex-col items-center justify-center">
								<Warning className="mb-4 h-8 w-8 fill-current text-amYellow" />
								<div className="alert mb-3 flex items-center justify-start rounded-lg bg-amYellow p-3">
									<p
										className="small-text-alert-modal-2 small-text-alert-modal m-0 ml-2 leading-normal"
										dangerouslySetInnerHTML={{
											__html: data.endAlert
										}}
									/>
								</div>
							</div>
						) : null}

						{!!data.secondContent ? (
							<div
								className="regular-text-content prose pt-4 font-normal text-amBlack"
								style={{ marginBottom: '20px' }}
								dangerouslySetInnerHTML={{
									__html: data.secondContent
								}}
							/>
						) : null}
						{!!data.table ? (
							<div className="overflow-hidden rounded-lg">
								{Array.isArray(theTable)
									? theTable.map((item, index) => (
											<div
												key={item.name}
												className={`flex w-full items-start justify-start py-2 text-white bg-${
													(index + 1) % 2 === 0
														? 'amBlack'
														: 'amBlue'
												}`}
											>
												<div className="w-8/12 px-4">
													{item.name}
												</div>
												<div className="w-4/12 px-4 text-right">
													{item.value}
												</div>
											</div>
									  ))
									: null}
							</div>
						) : null}
						{!!data.endContent ? (
							<div
								className="regular-text-content prose pt-4 font-normal text-amBlack"
								style={{ marginBottom: '20px' }}
								dangerouslySetInnerHTML={{
									__html: data.endContent
								}}
							/>
						) : null}
					</div>
				</div>
			</div>
		</Modal>
	);
};
