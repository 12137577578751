import React, { Component } from 'react';
// import parse from 'html-react-parser';
import './css/travelbenefits.css';
// import imagepases from '../../../assets/images/profile/travelicon.svg';
import imagepases01 from '../../../assets/travel/inter.svg';
import sujetoespacio from '../../../assets/travel/avion-sujeto.svg';
import zed from '../../../assets/travel/zed.svg';
import nacional from '../../../assets/travel/nacional.svg';
import derecho from '../../../assets/travel/derecho.svg';
import i18next from 'i18next';

import TravelOpensUpgrade from './travelOpensUpgrade';
// import userLocation from '../../../utils/userLocation';

import imagepases02 from '../../../assets/travel/avion-sujeto.svg';

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemPanel,
	AccordionItemButton
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import Loader from '../../Loader/Loader';
import { Trans } from 'react-i18next';
import { covidTickets } from '../../../utils/isTravelBenefitsActive';
import { motion } from 'framer-motion';

// import navidad from "../../../assets/images/profileBeneet/navidad.png";
import { isLocal, isFromDev, isFromQA } from './../../../core/Ambientes';


// # Variables que switchean las ligas que se consumen segun sea elcaso
// const isLocal = process.env.REACT_APP_ISLOCAL; // Tomara entorno local
// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA
/* Para productivo se ponen todas en falso */

// #URl de ambiente
const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`

const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`

// # Liga validada
const urlValid = isLocal === true ? urlLocal 
	: isFromDev === true ? urlDev
	: isFromQA  === true? urlQA
	: urlProd;
	
// Se genera el token segun el ambiente
const tokenValid = isLocal === true ? tokenLocal
	: isFromDev === true ? tokenDev
	: isFromQA === true ? tokenQA
	: tokenProd;

export default class travelbenefits extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			loading: true,
			language: i18next.language,
			nivel: parseInt(localStorage.getItem('nivel')) || 1,
			modalCovid: false
		};
	}
	async componentDidMount() {
		const cabecera = new Headers({
			'Content-type': 'application/json',
			'Authorization':'Bearer ' + tokenValid
			// 'Authorization': `Bearer ff1d53d0f81735945b6d90448172c60007e798da2be7b1667b735879c645f747e36521613bf9bda287b28bb3c8606650aefb8c6fb782eaab08bf46b9b27f0f7eb3e0dc6f805bb96d1ad5570e263c92d431cd27d93a16be1f224eae6ab3dc06805df63b778aca6ce80c808b7e5c599eabd491f897bc74119b61478a01d1a2e3ec`
		});
		await fetch(process.env.REACT_APP_CADMIN_PROD+'travel-benefit',
		{headers:cabecera})
			.then(response => {
				return response.json();
			})
			.then(async json => {
				// console.log("RESPONSE TRAVEL: ",json);
				// alert("RESPUESTA TRAVEL: " + JSON.stringify(json))
				await this.setState({
					...this.state,
					loading: false,
					data: json.data.attributes.json.data.content
				});
				// console.log(this.state);
			});
		// if (covidTickets()) {
		// 	setTimeout(() => {
		// 		this.setState({
		// 			...this.state,
		// 			modalCovid: true
		// 		});
		// 	}, 250);
		// }
	}

	hidingCookie = () => {
		this.setState({
			...this.state,
			modalCovid: false
		});
	};

	render() {
		// console.log('IDIOMA');
		// console.log(i18next.language);
		// console.log(this.state);
		if (this.state.loading) {
			return <Loader />;
		} else {
			const nivel = this.state.nivel;
			let content = this.state.data;
			if (this.state.language === 'es') {
				content = content.es;
			} else {
				content = content.en;
			}
			// console.log(content);
			// console.log(nivel);
			return (
				<motion.div
					initial={{ opacity: 0, y: 100 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 100 }}
					transition={{ delay: 0.15 }}
					className="container-guaranteed">
					{covidTickets() && (
						<div className="imoportantMesaggePrestacion blue flex">
							<div className="iconImpornt blue"></div>
							<p
								dangerouslySetInnerHTML={{
									__html: content.covid.politics
								}}></p>
						</div>
					)}
					{content.greenAlert && (
						<div className="imoportantMesaggePrestacion flex">
							<div className="iconImpornt green"></div>
							{/* <p>{localStorage.getItem('fechaContra').split("/")[2]}</p> */}
							{nivel < 10 && (
								<p
									dangerouslySetInnerHTML={{
										__html: content.greenAlertContent.L1_L9
									}}></p>
							)}
							{nivel > 9 && (
								<p
									dangerouslySetInnerHTML={{
										__html: content.greenAlertContent.L10_L12
									}}></p>
							)}
						</div>
					)}

					{content.yellowAlert && (
						<div className="imoportantMesaggePrestacion yellow">
							<div className="iconImpornt"></div>
							{nivel < 10 && (
								<p
									dangerouslySetInnerHTML={{
										__html: content.yellowAlertContent.L1_L9
									}}></p>
							)}
							{nivel > 9 && (
								<TravelOpensUpgrade
									text={content.yellowAlertContent.L10_L12}
									fakeAnchorText="Conoce el proceso completo para hacer un upgrade"
								/>
								// <p
								// 	dangerouslySetInnerHTML={{
								// 		__html: content.yellowAlertContent.L10_L12
								// 	}}
								// ></p>
							)}
						</div>
					)}

					<div className="TravelBenefitsDestktop">
						{/* COVID TICKETS */}
						{covidTickets() && (
							<div
								className="newTable"
								style={{
									background: "#012B5C",
									display: "flex"
								}}>
								<div className="tableWidth tableText">
									<div className="tableCell">
										<img src={sujetoespacio} alt="Covid Tickets" />
										<p className="title" style={{ color: "white" }}>
											{content.covid.first_column.title}
										</p>
										<p
											className="sub handle-travelbenefits2020-strong"
											style={{ color: "white" }}
											dangerouslySetInnerHTML={{
												__html: content.covid.first_column.subtitle
											}}
										/>
									</div>
								</div>

								<div className="tableWidth">
									<div className="travelPases" style={{ height: "100%" }}>
										<div className="rowPases">
											<p>
												<span>{content.covid.second_column.title}</span>
												<br />
												{content.covid.second_column.route}
											</p>
											{content.covid.second_column.details.map((list_item, index) => {
												if (index === 0) {
													return <p key={index}>{list_item}</p>;
												}
												if (index === 1 && this.state.nivel < 10) {
													return <p key={index}>{list_item}</p>;
												}
											})}
										</div>
										<div className="rowPases">
											<p>{content.covid.third_column.title}</p>
											{content.covid.third_column.details.map((list_item, index) => {
												return <p key={index}>{list_item}</p>;
											})}
										</div>
										<div className="rowPases">
											<p>{content.covid.fourth_column.title}</p>
											<p>{content.covid.fourth_column.details}</p>
										</div>
									</div>
								</div>
							</div>
						)}
						{/** Para Nivel L1 a L9 */}
						{nivel < 10 && content.pass && (
							<div className="newTable">
								<div className="tableWidth tableText">
									<div className="tableCell">
										<img src={derecho} />
										<p className="title">{content.pass.title}</p>
										<p className="sub">{content.pass.description}</p>
									</div>
								</div>
								{/* CONTENT.DATA INIT */}
								<div className="tableWidth">
									{content.pass.data.map((item) => {
										return (
											<div className="travelPases">
												<div className="rowPases">
													<p>
														<span>{item.column_one.title}</span>
														<br />
														{item.column_one.subtitle}
													</p>
													{item.column_one.list.map((list_item) => {
														if (list_item.danger) {
															return (
																<p
																	dangerouslySetInnerHTML={{
																		__html: list_item.text
																	}}></p>
															);
														} else {
															return <p>{list_item.text}</p>;
														}
													})}
												</div>
												<div className="rowPases">
													<p>{item.column_two.title}</p>
													{item.column_two.list.map((list_item) => {
														if (list_item.danger) {
															return (
																<p
																	dangerouslySetInnerHTML={{
																		__html: list_item.text
																	}}></p>
															);
														} else {
															return <p>{list_item.text}</p>;
														}
													})}
												</div>
												<div className="rowPases">
													<p>{item.column_three.title}</p>
													<p>{item.column_three.text}</p>
												</div>
											</div>
										);
									})}
								</div>
								{/* CONTENT.DATA END */}
							</div>
						)}

						{/** Para Nivel L9 SOLO */}

						{nivel === 9 && content.tourist_tickets && (
							<div className="newTable">
								<div className="tableWidth tableText">
									<div className="tableCell">
										<img src={imagepases02} alt="Pases turista" />
										<p className="title">{content.tourist_tickets.title}</p>
										<p className="sub"></p>
									</div>
								</div>

								<div className="tableWidth">
									{content.tourist_tickets.data.map((item) => {
										return (
											<div className="travelPases">
												<div className="rowPases">
													<p>
														<span>{item.column_one.title}</span>
														<br />
														{item.column_one.subtitle}
													</p>
													{item.column_one.list.map((list_item) => {
														if (list_item.danger) {
															return (
																<p
																	dangerouslySetInnerHTML={{
																		__html: list_item.text
																	}}></p>
															);
														} else {
															return <p>{list_item.text}</p>;
														}
													})}
												</div>
												<div className="rowPases">
													<p>{item.column_two.title}</p>
													{item.column_two.list.map((list_item) => {
														if (list_item.danger) {
															return (
																<p
																	dangerouslySetInnerHTML={{
																		__html: list_item.text
																	}}></p>
															);
														} else {
															return <p>{list_item.text}</p>;
														}
													})}
												</div>
												<div className="rowPases">
													<p>{item.column_three.title}</p>
													<p>{item.column_three.text}</p>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						)}

						{/** Para Nivel L1 a L9 */}

						{nivel < 10 && content.subject_space && (
							<div className="newTable">
								<div className="tableWidth tableText">
									<div className="tableCell">
										<img src={sujetoespacio} alt="Boletos sujetos a espacio" />
										<p className="title">{content.subject_space.title}</p>
										<p className="sub"></p>
									</div>
								</div>

								<div className="tableWidth">
									{content.subject_space.data.map((item) => {
										return (
											<div className="travelPases">
												<div className="rowPases">
													<p>
														<span>{item.column_one.title}</span>
														<br />
														{item.column_one.subtitle}
													</p>
													{item.column_one.list.map((list_item) => {
														return <p>{list_item.text}</p>;
													})}
												</div>
												<div className="rowPases">
													<p>{item.column_two.title}</p>
													{item.column_two.list.map((list_item) => {
														if (list_item.danger) {
															return (
																<p
																	dangerouslySetInnerHTML={{
																		__html: list_item.text
																	}}></p>
															);
														} else {
															return <p>{list_item.text}</p>;
														}
													})}
												</div>
												<div className="rowPases">
													<p>{item.column_three.title}</p>
													<p>{item.column_three.text}</p>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						)}

						{/*Clase Nivel 10 SOLO*/}

						{nivel > 9 && content.tourist_tickets_10 && (
							<div className="newTable">
								<div className="tableWidth tableText">
									<div className="tableCell">
										<img src={zed} />
										<p className="title">{content.tourist_tickets_10.title}</p>
									</div>
								</div>

								<div className="tableWidth">
									{content.tourist_tickets_10.data.map((item) => {
										return (
											<div className="travelPases">
												<div className="rowPases">
													<p>
														<span>{item.column_one.title}</span>
														<br />
														{item.column_one.subtitle}
													</p>
													{item.column_one.list.map((list_item) => {
														if (list_item.danger) {
															return (
																<p
																	dangerouslySetInnerHTML={{
																		__html: list_item.text
																	}}></p>
															);
														} else {
															return <p>{list_item.text}</p>;
														}
													})}
												</div>
												<div className="rowPases">
													<p>{item.column_two.title}</p>
													{item.column_two.list.map((list_item) => {
														if (list_item.danger) {
															return (
																<p
																	dangerouslySetInnerHTML={{
																		__html: list_item.text
																	}}></p>
															);
														} else {
															return <p>{list_item.text}</p>;
														}
													})}
												</div>
												<div className="rowPases">
													<p>{item.column_three.title}</p>
													<p>{item.column_three.text}</p>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						)}

						{/*Clase Nivel 10 SOLO Boletos turista*/}

						{nivel > 9 && content.subject_space_10 && (
							<div className="newTable">
								<div className="tableWidth tableText">
									<div className="tableCell">
										<img src={zed} />
										<p className="title">{content.subject_space_10.title}</p>
										{/* <p className="sub">(La reservación está sujeta a espacio en clase turista) </p>*/}
									</div>
								</div>

								<div className="tableWidth">
									{content.subject_space_10.data.map((item) => {
										return (
											<div className="travelPases">
												<div className="rowPases">
													<p>
														<span>{item.column_one.title}</span>
														<br />
														{item.column_one.subtitle}
													</p>
													{item.column_one.list.map((list_item) => {
														if (list_item.danger) {
															return (
																<p
																	dangerouslySetInnerHTML={{
																		__html: list_item.text
																	}}></p>
															);
														} else {
															return <p>{list_item.text}</p>;
														}
													})}
												</div>
												<div className="rowPases">
													<p>{item.column_two.title}</p>
													{item.column_two.list.map((list_item) => {
														if (list_item.danger) {
															return (
																<p
																	dangerouslySetInnerHTML={{
																		__html: list_item.text
																	}}></p>
															);
														} else {
															return <p>{list_item.text}</p>;
														}
													})}
												</div>
												<div className="rowPases">
													<p>{item.column_three.title}</p>
													<p>{item.column_three.text}</p>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						)}

						{/** TODOS */}
						{content.zed && (
							<div className="newTable">
								<div className="tableWidth tableText">
									<div className="tableCell">
										<img src={zed} />
										<p className="title">{content.zed.title}</p>
										<p className="sub">{content.zed.description}</p>
									</div>
								</div>

								<div className="tableWidth">
									{content.zed.data.map((item) => {
										return (
											<div className="travelPases">
												<div className="rowPases">
													<p>
														<span>{item.column_one.title}</span>
														<br />
													</p>
													{item.column_one.list.map((list_item) => {
														if (list_item.danger) {
															return (
																<p
																	dangerouslySetInnerHTML={{
																		__html: list_item.text
																	}}></p>
															);
														} else {
															return <p>{list_item.text}</p>;
														}
													})}
												</div>
												<div className="rowPases">
													<p>{item.column_two.title}</p>
													{item.column_two.list.map((list_item) => {
														if (list_item.danger) {
															return (
																<p
																	dangerouslySetInnerHTML={{
																		__html: list_item.text
																	}}></p>
															);
														} else {
															return <p>{list_item.text}</p>;
														}
													})}
												</div>
												<div className="rowPases">
													<p>{item.column_three.title}</p>
													<p>{item.column_three.text}</p>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</div>
					{/*// TODO: Acomodar los Valores con el contenido del JSON */}

					{/* DATOS VERSION MOVIL - ACORDIONES */}
					<div className="TravelBenefitsMovil">
						<Accordion allowZeroExpanded="true" id="wrapperAccordionThis">
							{/* AQUI MOVIL DE COVIDTICKETS */}
							{covidTickets() && (
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<div className="tableBoletosHeader">
												<div className="floatDiv">
													<img src={sujetoespacio} alt="Boletos Covid" />
													<p className="title">{content.covid.first_column.title}</p>
												</div>
												<p
													className="subtitle"
													dangerouslySetInnerHTML={{
														__html: content.covid.first_column.subtitle
													}}></p>
												<p className="impuestos">Tarifa: impuestos</p>
												<p className="cantidad">{content.covid.second_column.title}</p>
											</div>
										</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<ul>
											<li>{content.covid.second_column.title}</li>
											<li>{content.covid.second_column.route}</li>
											<li
												dangerouslySetInnerHTML={{
													__html: content.covid.first_column.subtitle
												}}></li>
											{content.covid.second_column.details.map((item, index) => {
												if (index < 1) return <li key={index}>{item}</li>;
												if (index > 0 && nivel < 10) return <li key={index}>{item}</li>;
											})}
											<li>
												<strong>{content.covid.third_column.title}</strong>{" "}
												{content.covid.third_column.details[0]}
											</li>
										</ul>
									</AccordionItemPanel>
								</AccordionItem>
							)}
							{/* COVIDTICKETS END */}
							{nivel < 10 && content.pass && (
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<div className="tableBoletosHeader">
												<div className="floatDiv">
													<img src={nacional} />
													<p className="title">
														{i18next.t("MiExperAM.travel.pases.continenN1-9.movil.title")}
													</p>
												</div>
												<p className="subtitle">
													{i18next.t("MiExperAM.travel.pases.continenN1-9.movil.sub")}
												</p>
												<p className="impuestos">
													{i18next.t("MiExperAM.travel.pases.continenN1-9.movil.tarifa")}
												</p>
												<p className="cantidad">
													{i18next.t("MiExperAM.travel.pases.continenN1-9.movil.cantidad")}
												</p>
											</div>
										</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<ul>
											<li>
												<Trans i18nKey="MiExperAM.travel.pases.continenN1-9.movil.p1"></Trans>
											</li>
											<li>
												<Trans i18nKey="MiExperAM.travel.pases.continenN1-9.movil.p2"></Trans>
											</li>
										</ul>
									</AccordionItemPanel>
								</AccordionItem>
							)}
							{nivel < 10 && (
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<div className="tableBoletosHeader">
												<div className="floatDiv">
													<img src={imagepases01} />
													<p className="title">
														{i18next.t("MiExperAM.travel.pases.interconN1-9.movil.title")}
													</p>
												</div>
												<p className="subtitle">
													{i18next.t("MiExperAM.travel.pases.interconN1-9.movil.sub")}
												</p>
												<p className="impuestos">
													{i18next.t("MiExperAM.travel.pases.interconN1-9.movil.tarifa")}
												</p>
												<p className="cantidad">
													{i18next.t("MiExperAM.travel.pases.interconN1-9.movil.cantidad")}
												</p>
											</div>
										</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<ul>
											<li>
												<Trans i18nKey="MiExperAM.travel.pases.interconN1-9.movil.p1"></Trans>
											</li>
											<li>
												<Trans i18nKey="MiExperAM.travel.pases.interconN1-9.movil.p2"></Trans>
											</li>
										</ul>
									</AccordionItemPanel>
								</AccordionItem>
							)}
							{nivel === 9 && (
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<div className="tableBoletosHeader">
												<div className="floatDiv">
													<img src={derecho} />
													<p className="title">
														{i18next.t("MiExperAM.travel.turistaN9.main.movil.title")}
													</p>
												</div>
												<p className="subtitle">
													{i18next.t("MiExperAM.travel.turistaN9.main.movil.sub")}
												</p>
												<p className="impuestos">
													{i18next.t("MiExperAM.travel.turistaN9.main.movil.tarifa")}
												</p>
												<p className="cantidad">
													{i18next.t("MiExperAM.travel.turistaN9.main.movil.cantidad")}
												</p>
											</div>
										</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<ul>
											<li>
												<Trans i18nKey="MiExperAM.travel.turistaN9.main.movil.p1"></Trans>
											</li>
											<li>
												<Trans i18nKey="MiExperAM.travel.turistaN9.main.movil.p2"></Trans>
											</li>
										</ul>
									</AccordionItemPanel>
								</AccordionItem>
							)}
							{nivel < 10 && (
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<div className="tableBoletosHeader">
												<div className="floatDiv">
													<img src={sujetoespacio} />
													<p className="title">
														{i18next.t("MiExperAM.travel.espacioN1-9.main.movil.title")}
													</p>
												</div>
												<p className="subtitle">
													{i18next.t("MiExperAM.travel.espacioN1-9.main.movil.sub")}
												</p>
												<p className="impuestos">
													{i18next.t("MiExperAM.travel.espacioN1-9.main.movil.tarifa")}
												</p>
												<p className="cantidad">
													{i18next.t("MiExperAM.travel.espacioN1-9.main.movil.cantidad")}
												</p>
											</div>
										</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<ul>
											<li>
												<Trans i18nKey="MiExperAM.travel.espacioN1-9.main.movil.p1"></Trans>
											</li>
											<li>
												<Trans i18nKey="MiExperAM.travel.espacioN1-9.main.movil.p2"></Trans>
											</li>
											<li>
												<Trans i18nKey="MiExperAM.travel.espacioN1-9.main.movil.p3"></Trans>
											</li>
										</ul>
									</AccordionItemPanel>
								</AccordionItem>
							)}
							{nivel > 9 && (
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<div className="tableBoletosHeader">
												<div className="floatDiv">
													<img src={imagepases01} />
													<p className="title">
														{i18next.t("MiExperAM.travel.turistaN10-12.main.movil.title")}
													</p>
												</div>
												<p className="subtitle">
													{i18next.t("MiExperAM.travel.turistaN10-12.main.movil.sub")}
												</p>
												<p className="impuestos">
													{i18next.t("MiExperAM.travel.turistaN10-12.main.movil.tarifa")}
												</p>
												<p className="cantidad">
													{i18next.t("MiExperAM.travel.turistaN10-12.main.movil.cantidad")}
												</p>
											</div>
										</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<ul>
											<li>
												<Trans i18nKey="MiExperAM.travel.turistaN10-12.main.movil.p1"></Trans>
											</li>
											<li>
												<Trans i18nKey="MiExperAM.travel.turistaN10-12.main.movil.p2"></Trans>
											</li>
										</ul>
									</AccordionItemPanel>
								</AccordionItem>
							)}
							{nivel > 9 && (
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<div className="tableBoletosHeader">
												<div className="floatDiv">
													<img src={sujetoespacio} />
													<p className="title">
														{i18next.t("MiExperAM.travel.espacioN10-12.main.movil.title")}
													</p>
												</div>
												<p className="subtitle">
													{i18next.t("MiExperAM.travel.espacioN10-12.main.movil.sub")}
												</p>
												<p className="impuestos">
													{i18next.t("MiExperAM.travel.espacioN10-12.main.movil.tarifa")}
												</p>
												<p className="cantidad">
													{i18next.t("MiExperAM.travel.espacioN10-12.main.movil.cantidad")}
												</p>
											</div>
										</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<ul>
											<li>
												<Trans i18nKey="MiExperAM.travel.espacioN10-12.main.movil.p1"></Trans>
											</li>
											<li>
												<Trans i18nKey="MiExperAM.travel.espacioN10-12.main.movil.p2"></Trans>
											</li>
										</ul>
									</AccordionItemPanel>
								</AccordionItem>
							)}
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										<div className="tableBoletosHeader">
											<div className="floatDiv">
												<img src={zed} />
												<p className="title">
													{i18next.t("MiExperAM.travel.zed.main.movil.title")}
												</p>
											</div>
											<p className="subtitle">
												{i18next.t("MiExperAM.travel.zed.main.movil.sub")}
											</p>
											<p className="impuestos">
												{i18next.t("MiExperAM.travel.zed.main.movil.tarifa")}
											</p>
											<p className="cantidad">
												{i18next.t("MiExperAM.travel.zed.main.movil.cantidad")}
											</p>
										</div>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<ul>
										<li>
											<Trans i18nKey="MiExperAM.travel.zed.main.movil.p1"></Trans>
										</li>
										<li>
											<Trans i18nKey="MiExperAM.travel.zed.main.movil.p2"></Trans>
										</li>
										<li>
											<Trans i18nKey="MiExperAM.travel.zed.main.movil.p3"></Trans>
										</li>
									</ul>
								</AccordionItemPanel>
							</AccordionItem>
						</Accordion>
					</div>
					{/* ALERTA AZUL */}
					{content.blueAlert && (
						<div className="imoportantMesaggePrestacion blue flex" style={{ background: "#0000E3" }}>
							<div className="iconImpornt blue"></div>
							{/* <p>{localStorage.getItem('fechaContra').split("/")[2]}</p> */}
							{nivel < 10 && (
								<p
									dangerouslySetInnerHTML={{
										__html: content.blueAlertContent.L1_L9
									}}></p>
							)}
							{nivel > 9 && (
								<p
									dangerouslySetInnerHTML={{
										__html: content.blueAlertContent.L10_L12
									}}></p>
							)}
						</div>
					)}
					<div className="imoportantMesaggePrestacion yellow flex">
						<div className="iconImpornt"></div>
						<p>
							Tus boletos Travel Benefits tienen vigencia de un año para su uso a partir de la fecha de
							emisión. Una vez que estos hayan expirado, tienes 30 días para solicitar el reembolso del
							monto pagado.{" "}
							<a
								href={i18next.t("MiExperAM.travel.masInfo.reembolsosLink")}
								target="_blank"
								rel="noopener noreferrer">
								Conoce más
							</a>
						</p>
					</div>
				</motion.div>
			);
		}
	}
}